import { COLORS, FONTS } from 'config/constants';

const styles = (theme) => ({
  formGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: '20%',
    '& div:nth-of-type(1)': {
      marginTop: '10px !important'
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 'unset',
      marginBottom: 40
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 'unset',
      marginBottom: 40
    },
  },
  inputField: {
    color: COLORS.black,
    width: '100%',
    height: 40,
    border: 0,
    borderRadius: 6,
    padding: '0 20px',
    marginTop: 36,
    font: FONTS.defaultSansFont,
    fontSize: theme.spacing(2),
    backgroundColor: COLORS.white,
    boxShadow: '0px 2px 4px #00000040',
    '&::placeholder': {
      color: '#00000033',
      letterSpacing: '0.28px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      fontFamily: 'Roboto, sans-serif',
      lineHeight: '40px',
      opacity: 1
    },
    '&:first-child': {
      marginTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      height: 36,
    },
    '& .MuiFormHelperText-root': {
      marginTop: theme.spacing(2),
    },
  },
  labelContainer: {
    width: '100%',
    justifyContent: 'end',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 12,
    lineHeight: '14px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: '0.24px',
    padding: '5px 0',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  icon: {
    width: 16,
    height: 16,
    borderRadius: '3px',
    backgroundColor: COLORS.white,
    [theme.breakpoints.up('md')]: {
      width: 15,
      height: 15,
    },
    [theme.breakpoints.up('sm')]: {
      width: 15,
      height: 15,
    }
  },
  checkedIcon: {
    backgroundColor: '#4B87C4',
    '&:before': {
      display: 'block',
      width: 17,
      height: 17,
      backgroundImage:
        'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
        ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
        '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
      content: '""',
      [theme.breakpoints.up('md')]: {
        width: 16,
        height: 16,
      },
      [theme.breakpoints.up('lg')]: {
        width: 16,
        height: 16,
      },
    },
  },
  checkLabel: {
    color: COLORS.white,
    font: FONTS.defaultSansFont,
    fontSize: 12,
  },
  buttonContainer: {
    width: '100%',
    maxWidth: 335,
    height: 45,
    marginLeft: 'auto',
    '& button': {
      backgroundColor: '#ffffff',
      borderRadius: '32px',
      boxShadow: '0px 3px 3px #00000026',
      fontSize: 21,
      '& span': {
        color: '#26A7DF',
        letterSpacing: '1.4px',
      }
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 247,
      margin: '0 auto',
      height: 33,
      '& button': {
        fontSize: '14px !important',
      },
    },
    [theme.breakpoints.down('md')]: {
      '& button': {
        fontSize: 16,
      },
    },
  },
  authLinks: {
    color: '#FFFFFF',
    cursor: 'pointer',
    opacity: 1,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 12,
    lineHeight: '14px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 0.24,
    '&:last-child': {
      display: 'inline-block',
      marginTop: 10
    },
  },
  authLinkContainer: {
    width: 190,
    fontWeight: 300,
    marginLeft: 'auto',
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      width: 235
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center'
    }
  },
  inlineInputContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  inlineInputs: {
    marginLeft: 10,
    marginTop: 0,
    '&:first-child': {
      marginLeft: 0
    }
  },
  inlineField: {
    width: 'calc(50% - 5px)',
    marginTop: '10px',
  },
  modalButtonContainer: {
    '& button': {
      minWidth: '334px',
      height: '45px',
      borderRadius: 23,
      '& span': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 21,
        lineHeight: '24px',
        fontFamily: 'Roboto, sans-serif',
        letterSpacing: 2.1,
        color: '#FFFFFF',
      }
    },
    [theme.breakpoints.down('sm')]: {
      left: '25%',
      '& button': {
        minWidth: '175px'
      }
    }
  },
  greetWrap: {
    flex: 1,
  },
  loginFormWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px'
    }
  },
  resetPwdForm: {
    '& h5': {
      maxWidth: 533,
      width: '100%'
    },
    '& form': {
      maxWidth: 533,
      marginRight: 'unset',
    },
    width: '100%',
    [theme.breakpoints.down('md')]: {
      '& h5': {
        maxWidth: 'unset',
        width: '100%'
      },
    }
  },
  errorImg: {
    position: 'absolute',
    top: 30
  },
  modalHeader: {
    display: 'flex',
    borderBottom: '1px solid lightgray'
  },
  checkContainer: {
    display: 'flex',
    // width: '100%',
    // margin: '0 auto',
    // [theme.breakpoints.down('md')]: {
      
    //   margin: '0 25%'
    // },
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      margin: 'auto'
    }
  },
  signupLabelContainer: {
    width: '100%',
    justifyContent: 'end',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 12,
    lineHeight: '14px',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: '0.24px',
    marginRight: 2,
    padding: '5px 0',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      width: 'unset',
      minWidth: 'fit-content'
    }
  },
  termsNPerms: {
    color: COLORS.white,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 12,
    lineHeight: '14px',
    fontFamily: 'Roboto, sans-serif',
    '&:hover': {
      cursor: 'pointer',
    },
  }
});

export default styles;
